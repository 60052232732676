import { useState } from "react";
import {
  getAuth,
  createUserWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
} from "firebase/auth";
import { useNavigate } from "react-router-dom";

export function Signup() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleSignup = async (e) => {
    e.preventDefault();

    const auth = getAuth();
    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );

      // Set user credential in localStorage
      localStorage.setItem("userCredential", JSON.stringify(userCredential));
    } catch (error) {
      console.error(error);
    }
  };

  const handleGoogleSignup = async () => {
    const auth = getAuth();
    const provider = new GoogleAuthProvider();
    try {
      const userCredential = await signInWithPopup(auth, provider);

      // Set user credential in localStorage
      localStorage.setItem("userCredential", JSON.stringify(userCredential));
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="bg-black">
      <form
        onSubmit={handleSignup}
        className="h-screen flex flex-col justify-center items-center text-white"
      >
        <h1 className="text-4xl font-bold mb-10">Spares Maker</h1>
        <input
          type="email"
          placeholder="Email"
          className="mb-10 p-3 rounded bg-transparent border border-white focus:border-white"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          className="mb-10 p-3 rounded bg-transparent border border-white focus:border-white"
          onChange={(e) => setPassword(e.target.value)}
        />
        <button
          type="submit"
          className="mb-10 bg-gray-800 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
        >
          Sign up
        </button>
        <span>
          Already user?{" "}
          <button
            className="text-red-800 bg-white px-2 rounded ms-2"
            onClick={() => navigate("/login")}
          >
            Log in
          </button>
        </span>
        <div className="mt-5">
          <button
            className="bg-white py-2 px-5 rounded-full text-black font-bold flex items-center gap-2"
            onClick={handleGoogleSignup}
          >
            <img
              src="https://img.icons8.com/color/16/000000/google-logo.png"
              alt="Google Logo"
            />
            <span>Sign up with Google</span>
          </button>
        </div>
      </form>
    </div>
  );
}
