import React, { useEffect, useState } from "react";
import {
  getFirestore,
  collection,
  onSnapshot,
  updateDoc,
  doc,
  deleteDoc,
} from "firebase/firestore";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import { app } from "../firebase";
import { PieChart } from "react-minimal-pie-chart";
import { Button, Tooltip } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import DeleteIcon from "@mui/icons-material/Delete";

const db = getFirestore(app);
const storage = getStorage(app);

function Dashboard({ user }) {
  const [data, setData] = useState([]);
  useEffect(() => {
    const unsubscribe = onSnapshot(collection(db, "quotes"), (snapshot) => {
      const newData = snapshot.docs.map((doc) => {
        const {
          description,
          fileUrl,
          status,
          userName,
          userEmail,
          created,
          filename,
        } = doc.data();
        return {
          id: doc.id,
          description,
          fileUrl,
          status,
          userName,
          userEmail,
          created,
          filename,
        };
      });
      setData(newData);
    });
    return () => unsubscribe();
  }, [db]);
  const timeConvert = (timestamp) => {
    const date = new Date(
      timestamp.seconds * 1000 + Math.floor(timestamp.nanoseconds / 1000000)
    );
    return date.toISOString();
  };

  const handleStatusChange = async (id, status) => {
    await updateDoc(doc(db, "quotes", id), {
      status: status,
    });
  };

  const handleDownload = async (url) => {
    const fileUrl = await getDownloadURL(ref(storage, url));
    window.open(fileUrl);
  };

  console.log(doc);

  const deleteQuote = async (quoteId) => {
    try {
      // Delete the quote from Firestore
      await deleteDoc(doc(db, "quotes", quoteId));
      // Update the quotes state by filtering out the deleted quote
      setData((prevQuotes) =>
        prevQuotes.filter((quote) => quote.id !== quoteId)
      );
    } catch (error) {
      console.error("Error deleting quote:", error);
    }
  };

  if (user?.email !== "bizusain@gmail.com") {
    return (
      <div className="bg-black text-white h-screen font-bold text-3xl pt-10 text-center">
        <p>Access denied. You are not authorized to view this page.</p>
      </div>
    );
  }

  // const totalCount = data.length;
  // const todayCount = data.filter((doc) => {
  //   const docDate = timeConvert(doc.date.created); // Assuming you have a "date" field in your data
  //   const today = new Date();
  //   return (
  //     docDate.getDate() === today.getDate() &&
  //     docDate.getMonth() === today.getMonth() &&
  //     docDate.getFullYear() === today.getFullYear()
  //   );
  // }).length;
  // const weekCount = data.filter((doc) => {
  //   const docDate = timeConvert(doc.date.created); // Assuming you have a "date" field in your data
  //   const today = new Date();
  //   const oneWeekAgo = new Date();
  //   oneWeekAgo.setDate(today.getDate() - 7);
  //   return docDate >= oneWeekAgo && docDate <= today;
  // }).length;
  // const monthCount = data.filter((doc) => {
  //   const docDate = timeConvert(doc.date.created); // Assuming you have a "date" field in your data
  //   const today = new Date();
  //   const oneMonthAgo = new Date();
  //   oneMonthAgo.setMonth(today.getMonth() - 1);
  //   return docDate >= oneMonthAgo && docDate <= today;
  // }).length;

  // const pieChartData = [
  //   { title: "Received", value: totalCount, color: "#3CBBB1" },
  //   { title: "Pending", value: totalCount - todayCount, color: "#C4C4C4" },
  // ];

  return (
    <div className="flex flex-col items-center justify-center bg-black">
      {/* <div className="bg-black text-white h-screen font-bold text-3xl  text-center">
        <h1 className="text-3xl font-bold">Dashboard</h1> */}
      {/* <div className="flex justify-around">
          <div>
            <h3 className="text-xl font-bold">Total Received Documents</h3>
            <PieChart
              data={pieChartData}
              lineWidth={25}
              animate
              animationDuration={1000}
              animationEasing="ease-out"
            />
          </div>
          <div>
            <h3 className="text-xl font-bold">Documents Count</h3>
            <div>
              <p>Today: {todayCount}</p>
              <p>This Week: {weekCount}</p>
              <p>This Month: {monthCount}</p>
            </div>
          </div>
      </div>
        </div> */}
      <h1 className="text-center text-white text-3xl py-8">All Quotes</h1>
      <table className="table-auto bg-white w-[80%] rounded-lg mb-10">
        <thead className="h-[70px] border-b-2">
          <tr>
            <th>User</th>
            <th>Email</th>
            <th>File Name</th>
            <th>Description</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody className="text-center ">
          {data.map((doc) => (
            <tr key={doc.id} className="border-b-2 h-[50px] text-sm">
              <td>{doc.userName}</td>
              <td>{doc.userEmail}</td>
              <Tooltip title={doc.filename}>
                <td>
                  {doc.filename?.length < 12
                    ? doc.filename
                    : doc.filename?.substring(0, 12) + "..."}
                </td>
              </Tooltip>
              <Tooltip title={doc.description}>
                <td>
                  {doc.description?.length < 12
                    ? doc.description
                    : doc.description?.substring(0, 12) + "..."}
                </td>
              </Tooltip>
              <td>
                <select
                  value={doc.status}
                  onChange={(e) => handleStatusChange(doc.id, e.target.value)}
                  className="border-2 p-1 rounded-lg "
                >
                  <option value="pending" className="bg-yellow-500 text-white ">
                    Pending
                  </option>
                  <option value="approved" className="bg-green-500 text-white">
                    Approved
                  </option>
                  <option value="rejected" className="bg-red-500 text-white">
                    Rejected
                  </option>
                </select>
              </td>
              <td>
                <Tooltip title="Download">
                  <Button
                    color="success"
                    variant="contained"
                    onClick={() => handleDownload(doc.fileUrl)}
                  >
                    <DownloadIcon />
                  </Button>
                </Tooltip>
                <Tooltip title="Delete">
                  <Button
                    variant="contained"
                    color="error"
                    sx={{ marginLeft: "15px" }}
                    onClick={() => deleteQuote(doc.fileUrl)}
                  >
                    <DeleteIcon />
                  </Button>
                </Tooltip>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default Dashboard;
