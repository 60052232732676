import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
// import DragDrop from "../../components/Drag";
// import ToolsImage from "../../assets/tools.jpg";
import GppGoodIcon from "@mui/icons-material/GppGood";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { Button, Tooltip } from "@mui/material";
import BackgroundImage from "./machine.webp";
import Logo from "../../assets/logo.png";
import ConnectingAirportsIcon from "@mui/icons-material/ConnectingAirports";
import SchoolIcon from "@mui/icons-material/School";
import FactoryIcon from "@mui/icons-material/Factory";
import RvHookupIcon from "@mui/icons-material/RvHookup";
import CableIcon from "@mui/icons-material/Cable";
import MedicalServicesIcon from "@mui/icons-material/MedicalServices";
import ProductionQuantityLimitsIcon from "@mui/icons-material/ProductionQuantityLimits";
import EnergySavingsLeafIcon from "@mui/icons-material/EnergySavingsLeaf";
import PrecisionManufacturingIcon from "@mui/icons-material/PrecisionManufacturing";
import Mac1 from "./mar1.png";
import Mac2 from "./mar2.png";
import Mac3 from "./mar3.png";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import Mac4 from "./mar4.png";
import Mac5 from "./mar5.png";
import Ord1 from "./ord1.png";
import Ord2 from "./ord2.png";
import Ord3 from "./ord3.png";
import Ord4 from "./ord4.png";
import Tur1 from "./Tur1.png";
import Tur2 from "./Tur2.png";
import Tur3 from "./Tur3.png";
import BusinessIcon from "@mui/icons-material/Business";
import ConnectWithoutContactIcon from "@mui/icons-material/ConnectWithoutContact";
import EmailIcon from "@mui/icons-material/Email";
import PlaceIcon from "@mui/icons-material/Place";
import DocumentScannerIcon from "@mui/icons-material/DocumentScanner";
import WhatsAppButton from "../../components/WhatsappButton";

export const Navbar = ({ handleLogout, user }) => {
  const navigate = useNavigate();

  return (
    <div
      className="flex flex-row items-center justify-between w-full bg-black h-[80px]"
      style={{ backgroundImage: BackgroundImage }}
    >
      <h1
        className="text-white ms-5 sm:ms-1 text-xl cursor-pointer flex flex-row items-center"
        onClick={() => navigate("/")}
      >
        <img
          src={Logo}
          alt=""
          className="w-auto h-[150px] me-3 mt-2 sm:w-auto sm:h-[65px]"
        />
        <span className="text-xs text-gray-400"></span>
      </h1>
      <div className="flex flex-row items-center">
        <button
          onClick={() => navigate("/orders")}
          className="text-white me-5 sm:text-sm"
        >
          Orders
        </button>
        {user ? (
          <button
            onClick={() => {
              handleLogout();
              navigate("/");
            }}
            className="text-white me-5 bg-gray-800 px-2 rounded py-1 sm:h-[35px]"
          >
            Logout
          </button>
        ) : (
          <button
            onClick={() => navigate("/login")}
            className="text-white me-5 bg-gray-800 px-2 rounded py-1 sm:h-[35px]"
          >
            Login
          </button>
        )}
        <Link to="/quote">
          <button className="bg-green-800 text-white text-lg px-2 py-2 rounded me-10 sm:h-[35px] sm:p-1 sm:me-2">
            Quote
          </button>
        </Link>
      </div>
    </div>
  );
};

export const Footer = () => {
  return (
    <div className="container bg-[#072339] text-white flex flex-row sm:flex-col justify-center items-center h-[200px] w-full sm:h-screen">
      <div className="first flex-1 ms-10 sm:m-0 sm:mt-10">
        <img src={Logo} alt="" className="h-[90px]" />
        <p>© 2023 All Right Reserved</p>
      </div>
      <Tooltip title="Coming Soon">
        <div className="second flex-1 border-s-2 sm:border-s-0 sm:w-[70%] sm:border-t-2 sm:ps-10 sm:pt-10 ps-10">
          <DocumentScannerIcon
            style={{ textAlign: "center" }}
            sx={{ fontSize: 40 }}
          />
          <p className="mt-5">Terms & Condition</p>
          <p>Privacy Terms</p>
        </div>
      </Tooltip>
      <div className="second flex-1 border-x-2 ps-10 sm:border-x-0 sm:w-[70%] sm:border-y-2 sm:py-10 sm:mb-10">
        <ConnectWithoutContactIcon
          style={{ textAlign: "center" }}
          sx={{ fontSize: 40 }}
        />
        <span className="flex flex-row items-center mt-5">
          <LocalPhoneIcon sx={{ fontSize: 18, marginRight: "5px" }} />
          <p>+91-8142666141</p>
        </span>
        <span className="flex flex-row items-center mt-2">
          <EmailIcon sx={{ fontSize: 18, marginRight: "5px" }} />
          <p>Sparesmaker@protonmail.com</p>
        </span>
      </div>
      <div className="third flex-1 ps-10 sm:w-[70%] mb-10">
        <BusinessIcon sx={{ fontSize: 40 }} />
        <span className="flex flex-row items-center mt-2">
          <PlaceIcon sx={{ fontSize: 25, marginRight: "5px" }} />
          <p className="">
            1-121-159/4, VST colony, Street No 6, Nacharam Habsiguda,
            Secunderabad, Hyderabad, Telangana, 500017
          </p>
        </span>
      </div>
    </div>
  );
};

const Hero = () => {
  return (
    <div
      className="sm:bg-black"
      style={{
        backgroundImage:
          'linear-gradient(to right, rgba(0,0,0,1) 45%, rgba(0,0,0,0)), url("/path/to/image.jpg")',
      }}
    >
      <div className="hero flex flex-col p-[60px] text-white">
        <h1 className="text-4xl font-bold mb-3">Manufacturing on Demand</h1>
        <p className="mb-5 text-[#a3c0dd]">
          With over 2,000 manufacturers in India, our network has the capacity
          you need for prototyping and production.
        </p>
        <div className="flex flex-row items-center mb-5 text-[#a3c0dd]">
          <span className="flex flex-row items-center">
            <CalendarMonthIcon />{" "}
            <span className="ms-3 me-5">
              <p>Parts in as</p>
              <p> Fast as 3 Days</p>{" "}
            </span>
          </span>
          <GppGoodIcon />
          <span className="ms-3">
            <span className="ms-3 me-5">
              <p>Parts in as</p>
              <p> Fast as 3 Days</p>{" "}
            </span>
          </span>
        </div>
        <Link to="/quote">
          <Button variant="contained" className="w-[250px]">
            Get Your Instant Quote
          </Button>
        </Link>
        <p className="text-gray-400 text-sm ms-3 mt-1">
          {" "}
          * All uploads are secure and confidential.
        </p>
      </div>
    </div>
  );
};

const Info = () => {
  const Card = ({ title, desc, img }) => {
    return (
      <div className="flex flex-col items-center mt-10 me-10 ms-10">
        <img className="w-[100px] h-auto" src={img} alt="" />
        <h3 className="text-2xl font-semibold mb-3">{title}</h3>
        <p className="font-normal text-base">{desc}</p>
      </div>
    );
  };

  return (
    <div className="bg-black text-white p-[50px] text-center font-semibold text-3xl sm:hidden">
      <h1>Turbocharge the way you make custom parts</h1>
      <div className="flex flex-row items-center">
        <Card
          img={Tur1}
          title="Massive Network Opacity"
          desc="Instantly access the production capacity of over 2,000 manufacturers with wide-ranging capabilities and certifications across India. From your desktop. Strict NDA with our network to protect privacy."
        />
        <Card
          img={Tur2}
          title="Instant Quoting Engine"
          desc="Get DFM feedback, lead times, and pricing in a matter of clicks, not days. SparesMaker IQ℠ puts Data Science to work for you so you can easily choose the optimal price/lead time option for your project. Compatible with STEP, Mesh, Parasolid, and ACIS files."
        />
        <Card
          img={Tur3}
          title="Quality Assurance"
          desc="SparesMaker is ISO 9001:2015 certified. Our Manufacturing Supplier Network’s seamless integration, the in-house applications engineering team, and the SparesMaker Quality Assurance Lab enable us to back every part we ship."
        />
      </div>
    </div>
  );
};

const Category = () => {
  const IconText = ({ icon, title }) => {
    return (
      <div className="mb-5 flex flex-row justify-center text-left sm:ms-2">
        <div className="flex sm:flex-col sm-text-center flex-row items-center">
          <span className="">{icon}</span>
          <p className="ms-4 sm:ms-3 sm:text-sm">{title}</p>
        </div>
      </div>
    );
  };

  return (
    <div className="bg-black text-white pt-10 pb-10">
      <h1 className="text-3xl text-center font-semibold pt-10 sm:text-lg">
        Precision manufacturing applications for every industry
      </h1>
      <div className="grid grid-cols-3 gap-4 mt-10 ">
        <IconText
          title="Aerospace & Defense"
          icon={<ConnectingAirportsIcon sx={{ fontSize: 40 }} />}
        />
        <IconText
          title="Education"
          icon={<SchoolIcon sx={{ fontSize: 40 }} />}
        />
        <IconText
          title="Industrial"
          icon={<FactoryIcon sx={{ fontSize: 40 }} />}
        />
        <IconText
          title="Automotive"
          icon={<RvHookupIcon sx={{ fontSize: 40 }} />}
        />
        <IconText
          title="Electronics"
          icon={<CableIcon sx={{ fontSize: 40 }} />}
        />
        <IconText
          title="Medical & Dental"
          icon={<MedicalServicesIcon sx={{ fontSize: 40 }} />}
        />
        <IconText
          title="Consumer Products"
          icon={<ProductionQuantityLimitsIcon sx={{ fontSize: 40 }} />}
        />
        <IconText
          title="Energy"
          icon={<EnergySavingsLeafIcon sx={{ fontSize: 40 }} />}
        />
        <IconText
          title="Robotics & Machine-Building"
          icon={<PrecisionManufacturingIcon sx={{ fontSize: 40 }} />}
        />
      </div>
    </div>
  );
};

const Steps = () => {
  const Order = ({ number, title, desc, img }) => {
    return (
      <div className="flex flex-col items-center ms-5 sm:mt-10">
        <img src={img} alt="" className="w-[120px] h-[100px] me-10" />
        <div className="">
          <h2 className="text-2xl my-3 ms-2">{title}</h2>
          <div className="flex flex-row w-[80%]">
            <span className="text-7xl font-bold me-3">{number}</span>
            <span>{desc}</span>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="p-10 bg-black text-white pt-10">
      <h1 className="text-3xl text-center font-semibold pt-10 mb-10">
        How to Order Parts
      </h1>
      <div className="flex flex-row sm:flex-col  justify-around items-center">
        <Order
          title="Upload your CAD files"
          number="1"
          desc="
Multiple designs for different parts can be imported at the same time to produce a single quote. In less than a minute, a detailed cost estimation appears on the screen."
          img={Ord1}
        />
        <Order
          title="Choose the process"
          number="2"
          img={Ord2}
          desc="
Multiple designs for different parts can be imported at the same time to produce a single quote. In less than a minute, a detailed cost estimation appears on the screen."
        />
        <Order
          title="Order your parts online"
          number="3"
          img={Ord3}
          desc="
Multiple designs for different parts can be imported at the same time to produce a single quote. In less than a minute, a detailed cost estimation appears on the screen."
        />
        <Order
          title="Receive your parts"
          number="4"
          img={Ord4}
          desc="
Multiple designs for different parts can be imported at the same time to produce a single quote. In less than a minute, a detailed cost estimation appears on the screen."
        />
      </div>
    </div>
  );
};

const Cards = () => {
  const Card = ({ title, img }) => {
    const [show, setShow] = useState(false);
    return (
      <span
        className={`bg-black text-white flex flex-col items-center rounded ${
          show ? "p-3" : "pt-3 px-3"
        } border-gray-900 mt-10 sm:flex-row`}
        style={{
          borderWidth: "0.5px",
          transition: "0.5s ease",
        }}
        onMouseEnter={() => setShow(true)}
        onMouseLeave={() => setShow(false)}
      >
        <span className="sm:w-[170px] sm:ms-5">
          <h3 className="font-bold text-gray-200 ">{title}</h3>
        </span>
        <span>
          <img src={img} alt="" className="sm:w-[50px] " />
        </span>
        {show ? (
          <span className="sm:invisible sm:w-0">
            <Link to="/quote">
              <Button
                variant="contained"
                className="w-[150px] font-bold sm:me-[100px] "
              >
                Start a Quote
              </Button>
            </Link>
            <p className="text-xs text-gray-500 mt-2 text-center">
              * Learn More
            </p>
          </span>
        ) : (
          <Button
            variant="transparent"
            sx={{ color: "black" }}
            className="w-[150px] sm:hidden sm:invisible sm:w-0"
          >
            Start a Quote
          </Button>
        )}
        <Link to="/quote">
          <Button
            variant="contained"
            className="w-[100px] text-[#0e6aed]  invisible sm:visible font-bold"
            // style={{ display: "none" }}
          >
            Quote
          </Button>
        </Link>
      </span>
    );
  };

  return (
    <div className="flex flex-row justify-around items-center bg-black sm:flex-col">
      <Card title="CNC Machining" img={Mac1} />
      <Card title="3D Printing" img={Mac2} />
      <Card title="Sheet Metal" img={Mac3} />
      <Card title="Injection Moulding" img={Mac4} />
      <Card title="Die casting" img={Mac5} />
    </div>
  );
};

const Home = ({ handleLogout, user }) => {
  return (
    <div className="">
      {/* <Navbar handleLogout={handleLogout} user={user} /> */}
      {/* <DragDrop user={user} /> */}
      <Hero />
      <Cards />
      <Info />
      <hr className="w-[80%]" style={{ width: "80%" }} />
      <Category />
      <hr className="" />
      <Steps />
      <WhatsAppButton />
    </div>
  );
};

export default Home;
