import React, { useRef, useState } from "react";
import { getFirestore, collection, addDoc } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { app } from "../firebase";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const db = getFirestore(app);
const storage = getStorage(app);

function Drag({ user }) {
  const [files, setFiles] = useState([]);
  const [description, setDescription] = useState("");
  const [isDragOver, setIsDragOver] = useState(false);
  const fileInputRef = useRef(null);

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragOver(false);
    const newFiles = Array.from(e.dataTransfer.files);
    setFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragOver(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setIsDragOver(false);
  };

  const handleFileInputChange = (e) => {
    const newFiles = Array.from(e.target.files);
    setFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const handleFileRemove = (file) => {
    setFiles((prevFiles) => prevFiles.filter((f) => f !== file));
  };
  console.log("user", user);

  const handleSubmit = async (e) => {
    if (!user) {
      alert("Login To Submit a File");
    }
    e.preventDefault();
    // Create a unique folder for each user
    const folderRef = ref(storage, `${user.uid}`);

    // Upload files to Firebase Storage
    const uploadPromises = files.map(async (file) => {
      const fileRef = ref(folderRef, file.name);
      await uploadBytes(fileRef, file);
      return {
        filename: file.name,
        fileUrl: await getDownloadURL(fileRef),
      };
    });

    const uploadedFiles = await Promise.all(uploadPromises);

    // Save the information to Firestore
    await Promise.all(
      uploadedFiles.map((uploadedFile) =>
        addDoc(collection(db, "quotes"), {
          filename: uploadedFile.filename,
          description: description,
          fileUrl: uploadedFile.fileUrl,
          status: "pending",
          created: new Date(),
          userEmail: user.email,
          userName: user.displayName,
        })
      )
    );

    try {
      // Upload files and save information to Firestore

      // Show success toast
      toast.success("Quotes added", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000, // Close the toast after 3 seconds
        hideProgressBar: true,
        closeButton: false,
      });
    } catch (error) {
      // Show error toast
      toast.error("Failed to add quotes", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000, // Close the toast after 3 seconds
        hideProgressBar: true,
        closeButton: false,
      });
    }

    setFiles([]);
    setDescription("");
    fileInputRef.current.value = "";

    console.log("Quotes added");
  };

  return (
    <div
      className={`h-screen flex flex-col items-center justify-center bg-black ${
        isDragOver ? "border-4 border-green-500" : ""
      }`}
      onDrop={handleDrop}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
    >
      {/* <h1 className="text-3xl mb-4 text-white">Upload your Files here</h1> */}
      <label
        htmlFor="fileInput"
        className="custom-file-upload bg-black text-white border-2 border-blue-800 border-dashed rounded w-80vw max-w-lg py-6 px-7 flex flex-col items-center justify-center"
      >
        <input
          id="fileInput"
          type="file"
          accept="*"
          multiple
          onChange={handleFileInputChange}
          style={{ display: "none" }}
        />
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 62 38"
          width="62"
          height="38"
          className="mb-2"
        >
          <path
            fill="#d0d7dd"
            d="m51.7671 15.1735c.036-.4093.0601-.8186.0601-1.2362 0-7.69838-6.0497-13.9373-13.5146-13.9373-5.8774 0-10.8647 3.87813-12.7249 9.2819-1.5956-.82276-3.3877-1.29822-5.292-1.29822-6.2582 0-11.36577 5.00682-11.79875 11.34502-4.73072.3183-8.4752215 4.3619-8.4752215 9.3191 0 5.164 4.0612115 9.3522 9.0685615 9.3522h18.99911v-10.0054h-3.524c-.2566 0-.4931-.1448-.6134-.3804-.1203-.2316-.1042-.5168.0361-.736l7.1041-10.8406c.2566-.3927.89-.3927 1.1466 0l7.1041 10.8406c.1443.2192.1604.5044.0361.736-.1203.2356-.3568.3804-.6134.3804h-3.524v10.0054h17.5558v-.1819c5.2158-.9344 9.1808-5.6105 9.1808-11.2541.016-6.0115-4.4902-10.9315-10.2111-11.3905z"
          />
        </svg>
        <p className="text-white text-sm">
          {files.length > 0
            ? files.map((file) => (
                <div
                  key={file.name}
                  className="flex items-center bg-blue-900 p-3 mb-3"
                >
                  <span>{file.name}</span>
                  <button
                    className="ml-2 text-white bg-orange-700 pb-1 px-2 "
                    onClick={() => handleFileRemove(file)}
                  >
                    x
                  </button>
                </div>
              ))
            : "You can upload multiple files at once"}
        </p>
        <p className="text-white text-xs mt-2">
          All Quote: STEP, STP, SLDPRT, STL, SAT, 3DXML, 3MF, PRT, IPT, CATPART,
          X_T, PTC, X_B, DXF,DWS, DWF, DWG, PDF
        </p>
        <p className="text-white text-xs mt-4">
          * All uploads are secure and confidential
        </p>
        {user ? null : (
          <p className="text-red-500 bg-gray mt-5">Login to Drop a File</p>
        )}
      </label>
      <form
        onSubmit={handleSubmit}
        className="mt-10 w-full flex flex-col items-center justify-center"
      >
        <div className="flex items-center justify-center w-full">
          <textarea
            className="bg-black text-white border-2 border-white rounded w-[300px] py-2 px-3 text-sm placeholder-gray-300 focus:outline-none focus:ring-2 focus:ring-white focus:border-transparent"
            type="text"
            id="description"
            name="description"
            placeholder="Description"
            value={description}
            onChange={handleDescriptionChange}
          />
        </div>
        <button
          type="submit"
          className="bg-gray-900 px-10 py-3 text-white font-bold rounded mt-10"
        >
          Submit
        </button>
      </form>
    </div>
  );
}

export default Drag;
