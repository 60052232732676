import React from "react";
import Drag from "../components/Drag";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const Quote = ({ user, handleLogout }) => {
  return (
    <div>
      <Drag handleLogout={handleLogout} user={user} />
      <ToastContainer />
    </div>
  );
};

export default Quote;
