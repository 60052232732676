import React from "react";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
const WhatsAppButton = () => {
  return (
    <a
      href="https://wa.me/+918142666141" // Replace with your actual WhatsApp link
      target="_blank"
      rel="noopener noreferrer"
      className="fixed bottom-8 right-7 z-50 bg-green-500 hover:bg-green-600 text-white rounded-full p-2 shadow-lg"
    >
      <WhatsAppIcon sx={{ fontSize: "50px" }} />
    </a>
  );
};

export default WhatsAppButton;
