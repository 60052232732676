import React, { useState, useEffect } from "react";
import {
  getFirestore,
  collection,
  onSnapshot,
  query,
  where,
  deleteDoc,
  doc,
} from "firebase/firestore";
import auth, { app } from "../firebase";
import { formatDistance } from "date-fns";
import { Navbar } from "./home/Home";

const db = getFirestore(app);

export const timeConvert = (time) => {
  const date = new Date(time.seconds * 1000);
  return formatDistance(date, new Date(), { addSuffix: true });
};

function Orders({ user }) {
  const [quotes, setQuotes] = useState([]);
  const [activeTab, setActiveTab] = useState("active");
  const [userShown, setUserShown] = useState(false);
  // if (!user) {
  //   return (
  //     <div className="bg-black h-screen text-white">
  //       <h2>Login To Check Orders</h2>
  //     </div>
  //   );
  // }

  useEffect(() => {
    // get the current user's email address
    if (!user) {
      return;
    }
    const userEmail = auth.currentUser.email;
    // subscribe to changes in the Firestore collection
    const unsubscribe = onSnapshot(
      query(collection(db, "quotes"), where("userEmail", "==", userEmail)),
      (snapshot) => {
        const quotes = [];
        snapshot.forEach((doc) => {
          const data = doc.data();
          quotes.push({
            id: doc.id,
            filename: data.filename,
            description: data.description,
            fileUrl: data.fileUrl,
            status: data.status,
            created: data.created,
            userEmail: data.userEmail,
            userName: data.userName,
          });
        });
        setQuotes(quotes);
      }
    );

    return unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);

  const deleteQuote = async (quoteId) => {
    try {
      // Delete the quote from Firestore
      await deleteDoc(doc(db, "quotes", quoteId));
      // Update the quotes state by filtering out the deleted quote
      setQuotes((prevQuotes) =>
        prevQuotes.filter((quote) => quote.id !== quoteId)
      );
    } catch (error) {
      console.error("Error deleting quote:", error);
    }
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const filteredQuotes = quotes.filter((quote) => {
    if (activeTab === "active") {
      return quote.status !== "complete";
    } else if (activeTab === "complete") {
      return quote.status === "complete";
    }
    return true;
  });

  return (
    <div className="w-screen bg-black min-h-screen" style={{ height: "100%" }}>
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <span className="text-5xl font-bold block text-white gray-900 mb-5">
            My Orders
          </span>
          <span className="text-2xl block text-gray-600 mb-5">
            Here are all the quotes that have been generated in the past.
          </span>
        </div>
        <div className="flex justify-center mb-5">
          <button
            className={`px-4 py-2 mx-2 rounded ${
              activeTab === "active" ? "bg-blue-600 text-white" : "bg-gray-300"
            }`}
            onClick={() => handleTabChange("active")}
          >
            Active
          </button>
          <button
            className={`px-4 py-2 mx-2 rounded ${
              activeTab === "complete"
                ? "bg-blue-600 text-white"
                : "bg-gray-300"
            }`}
            onClick={() => handleTabChange("complete")}
          >
            Complete
          </button>
        </div>
        {user ? null : (
          <h2 className="text-white text-center font-bold text-4xl text-gray-700">
            *Login To Check Orders
          </h2>
        )}
        <div className="flex flex-col justify-center items-center">
          {filteredQuotes.map((quote) => (
            <div
              key={quote.id}
              className=" flex flex-col text-white  rounded overflow-hidden shadow-lg m-4 bg-gray-800 w-[70%]"
            >
              <div className="px-6 py-4">
                <div className="font-bold text-lg mb-2">
                  quote - {quote.filename}
                </div>
                <p className=" text-base">Desc - {quote.description}</p>
              </div>
              <div className="px-6 pt-4 pb-2">
                <span className="inline-block bg-green-600 rounded-full px-3 py-1 text-sm font-semibold text-white mr-2">
                  {quote.status}
                </span>
                <span className="text-gray-600 text-sm">
                  Created: {timeConvert(quote.created)}
                </span>
                <div className="text-right mt-2">
                  <a
                    href={quote.fileUrl}
                    className="bg-blue-600 text-white px-4 py-2 rounded me-3"
                    download
                  >
                    Download
                  </a>
                  <button
                    className="bg-red-600 text-white px-4 py-2 rounded"
                    onClick={() => deleteQuote(quote.id)}
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Orders;
