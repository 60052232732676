import {
  browserLocalPersistence,
  getAuth,
  onAuthStateChanged,
  setPersistence,
  signInWithCredential,
  signOut,
} from "firebase/auth";
import { useEffect, useState } from "react";
import { Login } from "./pages/Auth/Login";
import auth from "./firebase";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import Home, { Footer, Navbar } from "./pages/home/Home";
import { Signup } from "./pages/Auth/Signup";
import Admin from "./pages/Admin";
import Orders from "./pages/Orders";
import Quote from "./pages/Quote";
function App() {
  const [user, setUser] = useState(null);
  useEffect(() => {
    // Check if user is already logged in
    const userCredential = JSON.parse(localStorage.getItem("userCredential"));
    if (userCredential) {
      signInWithCredential(auth, userCredential).then((userCredential) => {
        setUser(userCredential.user);
      });
    }

    // Listen for changes in the user's authentication state
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);

      // Set user credential in localStorage for persistent login
      if (user) {
        setPersistence(auth, browserLocalPersistence)
          .then(() => {
            localStorage.setItem("userCredential", JSON.stringify(user));
          })
          .catch((error) => {
            console.error(error);
          });
      } else {
        localStorage.removeItem("userCredential");
      }
    });
    return () => unsubscribe();
  }, []);

  const handleLogout = async () => {
    const auth = getAuth();
    try {
      await signOut(auth);
      setUser(null);
      localStorage.removeItem("userCredential");
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="w-full">
      <Router>
        <Navbar handleLogout={handleLogout} user={user} />
        <Routes>
          <Route
            path="/"
            element={<Home handleLogout={handleLogout} user={user} />}
          />
          <Route
            path="/login"
            element={!user ? <Login /> : <Navigate to="/" />}
          />
          <Route
            path="/signup"
            element={!user ? <Signup /> : <Navigate to="/" />}
          />
          <Route path="admin" element={<Admin user={user} />} />
          <Route path="/orders" element={<Orders user={user} />} />
          <Route
            path="/quote"
            element={<Quote user={user} handleLogout={handleLogout} />}
          />
        </Routes>
      </Router>
      <Footer />
    </div>
  );
}

export default App;
