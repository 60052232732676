import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

export const firebaseConfig = {
  // Your Firebase project configuration object
  apiKey: "AIzaSyAEPLWStQB5H-knkKNukGN72rLhnx45xOM",
  authDomain: "spares-maker.firebaseapp.com",
  projectId: "spares-maker",
  storageBucket: "spares-maker.appspot.com",
  messagingSenderId: "123271676625",
  appId: "1:123271676625:web:60feb79ac5bb8524d37bf0",
  measurementId: "G-366CTTKH4E",
};

export const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export default auth;
